export class Event {
  constructor(date, city, title) {
    this.date = date;
    this.city = city;
    this.title = title;
  }
}
//insérez les dates au format YYYY-MM-DD
export const eventsList = {
  event1: new Event("2023-04-22 16:00", "Vullierens", "Mariage"),
  event2: new Event("2023-05-25 18:00", "Lausanne", "Soirée privée"),
  event3: new Event(
    "2023-06-02 18:00",
    "Chavannes- de- Bogis",
    "Soirée entreprise"
  ),
  event17: new Event(
    "2023-06-03 11:00",
    "Radio suisse romande ",
    "Kiosque à musique"
  ),
  event4: new Event("2023-06-17 20:00", "Luins", "Fête du village (public)"),
  event5: new Event("2023-06-24 22:00", "Aubonne", "Abbaye (public)"),
  event6: new Event("2023-06-25 20:00", "Aubonne", "Abbaye (public)"),
  event7: new Event(
    "2023-08-01 18:00",
    "Vufflens-le-Château",
    "Fête Nationale (public)"
  ),
  event8: new Event("2023-09-02 19:00", "Poliez-Pittet", "Anniversaire"),
  event9: new Event("2023-09-16 18:00", "Lausanne", "Mariage"),
  event10: new Event("2023-09-23 18:30", "Forel", "Anniversaire"),
  event11: new Event("2023-10-01 19:30", "Charmey", "Soirée Séminaire"),
  event12: new Event("2023-11-04 18:00", "Vinzel", "Soirée Caveau (public)"),
  event13: new Event(
    "2023-11-25 19:00",
    "Nyon",
    "Fête des Châtaignes (public)"
  ),
  event14: new Event(
    "2023-12-31 19:30",
    "Hôtel Diablerets",
    "Nouvel’an (public)"
  ),
  event15: new Event("2024-05-08 18:00", "Chavannes-de-Bogis", "Mariage"),
  event16: new Event("2024-05-22 18:00", "Vullierens", "Mariage"),
};
