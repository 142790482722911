import "bootstrap";
import { eventsList } from "./Events.js";

const today = new Date();
const eventContainer = document.getElementById("eventsContainer");
const button = document.getElementById("eventbutton");
let count = 3;

const cardHtmlTemplate = (event) => {
  const dateEtHeure = event.date;

  const [year, month, day] = dateEtHeure.split(" ")[0].split("-");
  const monthTranslated = new Intl.DateTimeFormat("fr-FR", {
    month: "long",
  }).format(new Date(year, month - 1, day));

  const [heure, minutes] = dateEtHeure.split(" ")[1].split(":");

  const heureAuFormatHHMM = `${heure}:${minutes}`;

  return `
    <li class="row p-1 mb-2">
    <div class="card">
      <div class="col-3 col-sm-2  align-self-center">
        <p class="card__day">${day}</p>
        <p class="card__month">${monthTranslated}</p>
      </div>
      <div class="col-7 col-lg-9  align-self-center">
        <div class="row">
          <div class="col-12 col-lg-5  align-self-center">
            <h2 class="card__title">
            ${event.title}
            </h2>
          </div>     
          <div class="col-12 col-lg-7  align-self-center">
            <p class="card__city">${event.city}</p>
          </div>    
        </div>    
      </div>
      <div class="col-2 col-lg-1  align-self-center">
        <p class="card__hour">${heureAuFormatHHMM}</p>
      </div>
      </div>
    </li>
    `;
};

const createUpcomingEvents = (count) => {
  const upcomingEvents = Object.values(eventsList)
    .filter((event) => new Date(event.date) >= today)
    .sort((event1, event2) => new Date(event1.date) - new Date(event2.date))
    .slice(0, count);

  eventContainer.innerHTML = "";

  upcomingEvents.forEach((event) => {
    const cardHtml = cardHtmlTemplate(event);
    eventContainer.innerHTML += cardHtml;
  });

  button.disabled = upcomingEvents.length < count;
  button.disabled && (button.innerHTML = "Plus aucun évenements");
};

createUpcomingEvents(count);

button.addEventListener("click", () => {
  count += 3;
  createUpcomingEvents(count);
});

const menuButton = document.querySelector(".menuMobileButton");
const closeButton = document.querySelector("#closeBtn");
const menucontainer = document.querySelector(".menuMobileContainer");
const bgOverlay = document.querySelector(".backgroundOverlay");
const body = document.querySelector("body");
const menuItems = document.querySelectorAll(".menuMobileList li");

const openMenu = () => {
  menucontainer.classList.add("open");
  bgOverlay.classList.add("open");
  body.classList.add("modal-open");
};
const closeMenu = () => {
  menucontainer.classList.remove("open");
  bgOverlay.classList.remove("open");
  body.classList.remove("modal-open");
};

const goToLink = (e) => {
  e.preventDefault();
  closeMenu();
  setTimeout(() => {
    window.location.href = e.target.getAttribute("href");
  }, 200);
};

menuItems.forEach((item) => {
  item.addEventListener("click", (e) => goToLink(e));
});

menuButton.addEventListener("click", openMenu);
closeButton.addEventListener("click", closeMenu);
